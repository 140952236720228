.ag-theme-scrypt-blotter {
  width: 100%;
  height: 100%;

  --ag-background-color: unset;
  --ag-odd-row-background-color: #0a101c;
  --ag-borders: none;
  --ag-borders-row: none;

  --ag-font-size: 14px;
  --ag-font-family: 'Ringside Narrow';
  --ag-foreground-color: #e0e1e3;

  --ag-header-height: 24px;
  --ag-row-height: 32px;

  font-weight: 350;
}

.ag-theme-scrypt-blotter .ag-root {
}

.ag-theme-scrypt-blotter .ag-header {
}

.ag-header-cell-text {
  font-size: 16px;
}

.ag-theme-scrypt-blotter .ag-header-row {
  color: #52565f;
  font-size: 14px;
}

.ag-theme-scrypt-blotter .ag-row {
}

.ag-theme-scrypt-blotter .ag-cell {
}

.ag-theme-scrypt-blotter .ag-header-cell {
}

.ag-theme-scrypt-blotter .ag-cell-focus {
  border-color: transparent !important;
}

.ag-theme-scrypt-blotter .ag-row-hover {
}

.ag-theme-scrypt-blotter .ag-overlay-loading-wrapper {
  background-color: unset;
}
